.MyOrders-Container{
    display: flex;
    flex-direction: column;
}
.MyOrders-Container .MyOrders-Body-Container{
    display: flex;
    justify-content: center;
    width:100%;
}
.MyOrders-Container .MyOrders-Body-Container .MyOrders-Body{
    width: 80%;
    margin-top: 50px;
}