.exclusive-messeges-container{
    display: flex;
    flex-direction: column;
}
.exclusive-messeges-container .exclusive-messeges-body-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body{
    position: relative;
    overflow: hidden;
    display: flex;
    width:80%;
    height: 80vh;
    border:solid 1px lightgray;
}

.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .chat-header-for-small-device{
   display: none;
}

/*Chat List Styling */
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-list{
    width:30%;
    border-right: solid 1px lightgray;
    display: flex;
    flex-direction: column;
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-list .list-header{
    height: 10vh;
    background-color: darkcyan;
    display: flex;
    justify-content: center;
    align-items: center;
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-list .list-body{
    height: 82vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-list .list-body .chat{ 
    display: flex;
    align-items: center;
    padding: 5px;
    border-bottom: solid 1px #c9c7c7 ;
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-list .list-body .chat:hover{ 
    background-color: #eeeeee;
    cursor: pointer;
}

.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-list .list-footer{
    height: 8vh;
    border-top: solid 1px lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:5px
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-list .list-footer .footer-search{
    border: solid 1px gray;
    width: 100%;   
}


/*Messeges Styling */
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat{
    width:70%;
    border-right: solid 1px lightgray;
    display: flex;
    flex-direction: column;
}


.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-header{
    height: 10vh;
    background-color: #B17E4E;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-header .Menu-Icon{
    display: none;
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body{
    overflow-y: auto;
    overflow-x: hidden;
    height: 82vh;
    padding: 5px;
    display: flex;
    flex-direction: column;
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user1{
    width: 100%;
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user1 .first-user-message{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user1 .first-user-message .avatar{
    width: 8%;
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user1 .first-user-message .msg{
    width: 92%;
    display: flex;
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user2{
    width: 100%;
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user2 .second-user-message{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user2 .second-user-message .avatar{
    width: 8%;
    display: flex;
    justify-content: flex-end;
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user2 .second-user-message .msg{
   width: 92%;
   display: flex;
   justify-content: flex-end;
}

.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .View:hover,img:hover{

   cursor: pointer;
}

.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-footer{
    height: 8vh;
    border-top: solid 1px lightgray;
    display: flex;
    align-items: center;
    width: 100%;
    padding:5px
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-footer .footer-input-box{ 
    display: flex;
    align-items: center;
    border: solid 1px gray;
    width: 70%;
    margin-right: 15px;
}
.exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-footer .footer-input-box .send-message-box{ 
    width: 80%;
}


@media(max-width:1024px)
{
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body{
        width: 95%;
    }
}

@media(max-width:768px)
{
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user1 .first-user-message .avatar{
        width: 12%;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user1 .first-user-message .msg{
        width: 88%;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user2 .second-user-message .avatar{
        width: 12%;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user2 .second-user-message .msg{
       width: 88%;
    }
    
}

@media(max-width:576px)
{
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-footer .footer-input-box{ 
        width: 100%;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body{
        height: 61vh;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat{
        width:100%;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-list{
       display: none;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body{
        display: flex;
        flex-direction: column;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .chat-header-for-small-device{
        height: 10vh;
        background-color: #B17E4E;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
        width:100%;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-header{
        display: none;
     }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat{
        width:100%;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-footer{
        height: 15vh;
        display:flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-footer  .send-message-box{ 
        width: 100%;
        border: solid 1px gray;
        margin-right:0;
    }

    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user1 .first-user-message .avatar{
        width: 15%;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user1 .first-user-message .msg{
        width: 85%;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user2 .second-user-message .avatar{
        width: 15%;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user2 .second-user-message .msg{
       width: 85%;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body{
        height: 85vh;
    }
    
}

@media(max-width:360px)
{
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user1 .first-user-message .avatar{
        width: 18%;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user1 .first-user-message .msg{
        width: 82%;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user2 .second-user-message .avatar{
        width: 18%;
    }
    .exclusive-messeges-container .exclusive-messeges-body-container .exclusive-messeges-body .exclusive-messeges-body-chat .chat-body .chat-body-user2 .second-user-message .msg{
       width: 82%;
    }
    
}