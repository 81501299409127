.seller-profile-Container{
    display: flex;
    flex-direction: column;
}
.seller-profile-Container .seller-profile-Body-Container{
    display: flex;
    width:100%;
}
.seller-profile-Container .seller-profile-Body-Container .seller-profile-Body{
    width: 80%;
    display: flex;
    flex-direction: column;
    padding:30px;
    height: 92vh;
    overflow-y: auto;
}
.seller-profile-Container .seller-profile-Body-Container .seller-profile-Body .seller-profile-setting{
    display:flex;
    flex-direction: column;
    align-items: center;
}
.seller-profile-Container .seller-profile-Body-Container .seller-profile-Body .seller-profile-setting .seller-profile-image{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    width: 60%;
}
.seller-profile-Container .seller-profile-Body-Container .seller-profile-Body .seller-profile-setting .form{
    width:60%;
}


@media(max-width:1200px)
{
    .seller-profile-Container .seller-profile-Body-Container .seller-profile-Body{
        width: 75%;
    }
}
@media(max-width:1024px)
{

    .seller-profile-Container .seller-profile-Body-Container .seller-profile-Body .seller-profile-setting .form{
        width:90%;
    }
    .seller-profile-Container .seller-profile-Body-Container .seller-profile-Body .seller-profile-setting .seller-profile-image{
        width: 90%;
    }
}
@media(max-width:992px)
{
    .seller-profile-Container .seller-profile-Body-Container .seller-profile-Body{
        width: 100%;
    }
    .seller-profile-Container .seller-profile-Body-Container .seller-profile-Body .seller-profile-setting .form{
        width:80%;
    }
}
@media(max-width:576px)
{
    .seller-profile-Container .seller-profile-Body-Container .seller-profile-Body{
        padding-right: 10px;
        padding-left: 10px;
    }

}
