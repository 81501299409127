.my-earnings-Container{
    display: flex;
    flex-direction: column;
}
.my-earnings-Container .my-earnings-Body-Container{
    display: flex;
    width:100%;
}
.my-earnings-Container .my-earnings-Body-Container .my-earnings-Body{
    width: 80%;
    display: flex;
    flex-direction: column;
    padding:30px;
    height: 92vh;
    overflow-y: auto;
}
.my-earnings-Container .my-earnings-Body-Container .my-earnings-Body .Header{
    display: flex;
    justify-content: space-between;
    align-items: center; 
}

@media(max-width:1200px){
    .my-earnings-Container .my-earnings-Body-Container .my-earnings-Body{
        width: 75%;
    }
}
@media(max-width:992px){
    .my-earnings-Container .my-earnings-Body-Container .my-earnings-Body{
        width: 100%;
    }
}