.seller-dashboard-Container{
    display: flex;
    flex-direction: column;
}
.seller-dashboard-Container .seller-dashboard-Body-Container{
    display: flex;
    width:100%;
}
.seller-dashboard-Container .seller-dashboard-Body-Container .seller-dashboard-Body{
    width: 80%;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    height: 92vh;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-bottom: 10px;

}
.seller-dashboard-Container .seller-dashboard-Body-Container .seller-dashboard-Body .seller-dashboard-Body-content{
    width: 95%;
}
.seller-dashboard-Container .seller-dashboard-Body-Container .seller-dashboard-Body .seller-dashboard-Body-content .header{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.seller-dashboard-Container .seller-dashboard-Body-Container .seller-dashboard-Body .seller-dashboard-Body-content .header .box{
    width:30%;
    border: solid 1px gray;
    border-radius: 5px;
}
.seller-dashboard-Container .seller-dashboard-Body-Container .seller-dashboard-Body .seller-dashboard-Body-content .header .box .box-header{
    padding:20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}
.seller-dashboard-Container .seller-dashboard-Body-Container .seller-dashboard-Body .seller-dashboard-Body-content .header .box .box-footer{
    padding:10px;
    background-color: #eeeeee;
    border-top: solid 1px gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media(max-width:1200px){
    .seller-dashboard-Container .seller-dashboard-Body-Container .seller-dashboard-Body{
        width: 75%;
    }
}
@media (max-width:992px){
    .seller-dashboard-Container .seller-dashboard-Body-Container .seller-dashboard-Body{
        width:100%;
    }
}
@media (max-width:768px){
    .seller-dashboard-Container .seller-dashboard-Body-Container .seller-dashboard-Body .seller-dashboard-Body-content .header{
        display: flex;
        flex-direction: column;
    }
    .seller-dashboard-Container .seller-dashboard-Body-Container .seller-dashboard-Body .seller-dashboard-Body-content .header .box{
        width:100%;
        margin-top: 20px;
    }
}