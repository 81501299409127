.job-description-container{
   display: flex;
   flex-direction: column;
}
.job-description-container .job-description-body-container{

    display: flex;
    align-items: center;
    justify-content: center;

}
.job-description-container .job-description-body-container .job-description-body{
    display: flex;
    width: 80%;
    margin-top: 50px;
}
.job-description-container .job-description-body-container .job-description-body .job-description-job-detail-section{
    width:50%;
}
.job-description-container .job-description-body-container .job-description-body .job-description-messege-section{
    width:50%;
    padding-left: 80px;
}
.job-description-container .job-description-body-container .job-description-body .job-description-job-detail-section .image{
    height: 200;
    width: 100;
}

.job-description-container .job-description-body-container .job-description-body .job-description-job-detail .job-description-price-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (max-width:1380px){

    .job-description-container .job-description-body-container .job-description-body{
        width: 90%;
    }
    

}
@media (max-width:1180px){
    .job-description-container .job-description-body-container .job-description-body .job-description-job-detail-section .image{
        width: 300;
     }

}

@media (max-width:991px){

    .job-description-container .job-description-body-container .job-description-body{
        flex-direction: column;
        align-items: center;
        width:85%;
    }
    .job-description-container .job-description-body-container .job-description-body .job-description-job-detail-section{
        width:100%;
    }
    .job-description-container .job-description-body-container .job-description-body .job-description-messege-section{
        width:100%;
        margin-top: 50px;
        padding-left: 0;
    }
}