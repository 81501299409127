.item-description-container{
   flex:1;
}
.item-description-container .item-description-inner-container{

    display: flex;
    justify-content: center;

}
.item-description-container .item-description-inner-container .item-description-body{
    display: flex;
    width: 80%;
    margin-top: 60px;
}
.item-description-container .item-description-inner-container .item-description-body .item-description-item-detail .item-description-price-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (max-width:1240px){

    .item-description-container .item-description-inner-container .item-description-body{
        width: 90%;
    }

}
@media (max-width:1020px){

    .item-description-container .item-description-inner-container .item-description-body{
        width: 95%;
    }

}
@media (max-width:940px){

    .item-description-container .item-description-inner-container .item-description-body{
        flex-direction: column;
        align-items: center;
        width:80%;
    }
    .item-description-container .item-description-inner-container .item-description-body .item-description-item-detail .item-description-price-section{
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
}
