.Privacy-Container{
    width:100%;
    height: 100vh;
    
}
.Privacy-Container .Privacy-Body-Container{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}
.Privacy-Container .Privacy-Body-Container .Privacy-Body{
    align-self:center;
    width:70%;
    padding: 30px;
    margin-top: -100px;
    background-color: #eeeeee;
    box-shadow: 2px 2px 5px 1px gray;
    margin-bottom: 20px;
    -webkit-touch-callout: none !important;
-webkit-user-select: none !important;
-khtml-user-select: none !important;
-moz-user-select: none !important;
-ms-user-select: none !important;
user-select: none !important;
overflow-y: scroll;
max-height: 800px;
}

@media(max-width:768px)
{
    .Privacy-Container .Privacy-Body-Container .Privacy-Body{
        width:90%;
    }
}