.myService-Description-Container{
    display: flex;
    flex-direction: column;
}
.myService-Description-Container .myService-Description-Body-Container{
    display: flex;
    width:100%;
}
.myService-Description-Container .myService-Description-Body-Container .myService-Description-Body{
    width: 80%;
    display: flex;
    flex-direction: column;
    padding:30px;
    height: 92vh;
    overflow-y: auto;
}

@media(max-width:1200px){
    .myService-Description-Container .myService-Description-Body-Container .myService-Description-Body{
        width: 75%;
    }
}
@media(max-width:992px){
    .myService-Description-Container .myService-Description-Body-Container .myService-Description-Body{
        width: 100%;
    }
}